import { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuth } from "../../context/authUserProvider";
import { Dialog, Transition, Listbox, Switch } from "@headlessui/react";
import { XMarkIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import {
  LinkIcon,
  PlusSmallIcon,
  QuestionMarkCircleIcon,
  CheckIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/20/solid";

import {
  getFirestore,
  collection,
  query,
  onSnapshot,
  updateDoc,
  where,
  getDoc,
  serverTimestamp,
  addDoc,
  setDoc,
  doc,
} from "firebase/firestore";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

const statusStyles = {
  true: "bg-green-100 text-green-800",
  false: "bg-yellow-100 text-yellow-800",
};

const statusButtonStyles = {
  true: "bg-cyan-500 text-white hover:bg-cyan-600",
  false: "bg-transparent hover:bg-transparent",
};

const statusButtonInStyles = {
  false: "bg-cyan-500 text-white hover:bg-cyan-600",
  true: "bg-transparent hover:bg-transparent",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const statusData = [
  {
    id: 1,
    status: true,
  },

  {
    id: 2,
    status: false,
  },
];

export default function NewProduct({
  onClickOpenNewProduct,
  onClickCloseNewProduct,
  // project,
}) {
  let navigate = useNavigate();
  const { authUser } = useAuth();
  const storage = getStorage();
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [productName, setProductName] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [partNumber, setPartNumber] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [macAddress, setMacAddress] = useState("");
  const [rate, setRate] = useState("");
  const [quantity, setQuantity] = useState("");

  const [category, setCategory] = useState({});
  const [type, setType] = useState({});

  const [productStatus, setProductStatus] = useState(false);

  const [productCategories, setProductCategories] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [setlectedProductCat, setSelectedProductCat] = useState();

  const [createStatus, setCreateStatus] = useState("");

  const onClickClose = () => {
    setImage(null);
    setImagePreview(null);
    onClickCloseNewProduct();
  };

  useEffect(() => {
    async function fetchProductCategories() {
      const db = getFirestore();
      const q = query(collection(db, "productCategories"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        var categories = [];
        querySnapshot.forEach((doc) => {
          categories.push(doc.data());
        });
        setProductCategories(categories);
      });
    }

    fetchProductCategories();
  }, []);

  useEffect(() => {
    async function fetchProductTypes() {
      const db = getFirestore();
      const q = query(collection(db, "productTypes"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        var types = [];
        querySnapshot.forEach((doc) => {
          types.push(doc.data());
        });
        setProductTypes(types);
      });
    }

    fetchProductTypes();
  }, []);

  const metadata = {
    contentType: "image/jpeg",
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      const image1 = e.target.files[0];
      setImagePreview(URL.createObjectURL(e.target.files[0]));
      setImage(image1);
    }
  };

  const onClickCUpdateProduct = async (e) => {
    e.preventDefault();
    if (
      productName !== "" &&
      modelNumber !== "" &&
      partNumber !== "" &&
      serialNumber !== "" &&
      macAddress !== "" &&
      rate !== "" &&
      quantity !== "" &&
      Object.keys(type).length !== 0 &&
      Object.keys(category).length !== 0 &&
      image !== null
    ) {
      const time = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS");
      const storageRef = ref(storage, "productImages/" + time + image.name);
      const uploadTask = uploadBytesResumable(storageRef, image, metadata);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //   console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              //   console.log("Upload is paused");
              setCreateStatus("Upload is paused");
              break;
            case "running":
              //   console.log("Upload is running");
              setCreateStatus("Upload is " + progress.toFixed(0) + "% done");
              break;
          }
        },
        (error) => {
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then(async (downloadURL) => {
              setCreateStatus("Adding product");
              const db = getFirestore();
              // const productRef = collection(db, "products");
              const productRef = doc(collection(db, "products"));
              await setDoc(doc(db, "products", productRef.id), {
                createdBy: authUser.email,
                timestamp: serverTimestamp(),
                productImage: downloadURL,
                productName: productName,
                modelNumer: modelNumber,
                productId: productRef.id,
                partNumber: partNumber,
                serialNumber: serialNumber,
                macAddress: macAddress,
                productRate: rate,
                quantity: quantity,
                type: type.name,
                catId: category.catId,
                active: productStatus,
              });
              setCreateStatus("Product added");
            })
            .then(() => {
              setTimeout(() => {
                onClickCloseNewProduct();
                setCreateStatus("");
                setImage(null);
              }, 2000);
            });
        }
      );
    } else {
      console.log("failed");
    }
  };

  return (
    <Transition.Root show={onClickOpenNewProduct} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={onClickCloseNewProduct}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="px-4 py-6 bg-gray-50 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            New product
                          </Dialog.Title>
                          <p className="text-sm text-gray-500">
                            Fill in to add new product
                          </p>
                        </div>
                        <div className="h-7 flex items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={onClickClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}
                    <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                      {/* image */}
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="reg-number"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Product image
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          {/* image */}

                          <section
                            aria-labelledby="social-impact-heading"
                            className="aspect-w-4 rounded-md overflow-hidden"
                          >
                            <div className="relative rounded-md overflow-hidden">
                              <div className="absolute inset-0 rounded-md">
                                <img
                                  src={imagePreview}
                                  alt=""
                                  className="w-full h-full object-center object-cover rounded-md"
                                />
                              </div>
                              <div className="relative rounded-md  bg-gray-900 bg-opacity-10  py-32 px-6 sm:py-40 sm:px-12 lg:px-16 ">
                                <div className="relative rounded-md  max-w-3xl mx-auto  flex flex-col items-center text-center">
                                  <div className="flex items-center rounded-md ">
                                    <div className=" rounded-md shadow-sm cursor-pointer">
                                      <div className="group relative border cursor-pointer border-gray-600 bg-white rounded-md py-2 px-3 flex items-center justify-center ">
                                        <label
                                          htmlFor="mobile-user-photo"
                                          className="text-gray-900  bg-white cursor-pointer rounded-md "
                                        >
                                          <span className="cursor-pointer rounded-md ">
                                            {image
                                              ? "Change Image"
                                              : "Upload Image"}
                                          </span>
                                          <span className="sr-only">logo</span>
                                        </label>

                                        <input
                                          accept="image/*"
                                          onChange={handleImageChange}
                                          id="mobile-user-photo"
                                          name="user-photo"
                                          type="file"
                                          className="absolute w-full h-full opacity-0 cursor-pointer border-gray-300 bg-white rounded-md"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>

                      {/* acc number */}
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="reg-number"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Product name
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <input
                            onChange={(e) => setProductName(e.target.value)}
                            value={productName}
                            type="text"
                            name="acc-number"
                            id="acc-number"
                            className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      {/* model number */}
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="reg-number"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Model number
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <input
                            onChange={(e) => setModelNumber(e.target.value)}
                            value={modelNumber}
                            type="text"
                            name="acc-number"
                            id="acc-number"
                            className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      {/* part number */}
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="reg-number"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Part number
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <input
                            onChange={(e) => setPartNumber(e.target.value)}
                            value={partNumber}
                            type="text"
                            name="acc-number"
                            id="acc-number"
                            className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      {/* serial number */}
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="reg-number"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Serial number
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <input
                            onChange={(e) => setSerialNumber(e.target.value)}
                            value={serialNumber}
                            type="text"
                            name="acc-number"
                            id="acc-number"
                            className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      {/* mac */}
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="reg-number"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            MAC address
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <input
                            onChange={(e) => setMacAddress(e.target.value)}
                            value={macAddress}
                            type="text"
                            name="acc-number"
                            id="acc-number"
                            className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      {/* Rate */}
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="reg-number"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Rate
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <input
                            onChange={(e) => setRate(e.target.value)}
                            value={rate}
                            type="text"
                            name="acc-number"
                            id="acc-number"
                            className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      {/* qty */}
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="reg-number"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Quantity
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <input
                            onChange={(e) => setQuantity(e.target.value)}
                            value={quantity}
                            type="text"
                            name="acc-number"
                            id="acc-number"
                            className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      {/* cat select */}

                      <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:px-6 sm:py-5">
                        <div>
                          <h3 className="text-sm font-medium text-gray-900">
                            Category
                          </h3>
                        </div>
                        <div className="sm:col-span-2">
                          <Listbox value={category} onChange={setCategory}>
                            {({ open }) => (
                              <>
                                <div className="mt-1 relative">
                                  <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-600 focus:border-cyan-600 sm:text-sm">
                                    <span className="block truncate capitalize">
                                      {category?.name
                                        ? category?.name
                                        : "Select category"}
                                    </span>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                      <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                      {productCategories.map((item) => (
                                        <Listbox.Option
                                          key={item.catId}
                                          className={({ active }) =>
                                            classNames(
                                              active
                                                ? "text-white bg-cyan-500"
                                                : "text-gray-900",
                                              "cursor-default select-none relative py-2 pl-3 pr-9"
                                            )
                                          }
                                          value={item}
                                        >
                                          {({ selected, active }) => (
                                            <>
                                              <span
                                                className={classNames(
                                                  selected
                                                    ? "font-semibold"
                                                    : "font-normal",
                                                  "block truncate"
                                                )}
                                              >
                                                {item.name}
                                              </span>

                                              {selected ? (
                                                <span
                                                  className={classNames(
                                                    active
                                                      ? "text-white"
                                                      : "text-cyan-500",
                                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </>
                            )}
                          </Listbox>
                        </div>
                      </div>

                      {/* type select */}

                      <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:px-6 sm:py-5">
                        <div>
                          <h3 className="text-sm font-medium text-gray-900">
                            Type
                          </h3>
                        </div>
                        <div className="sm:col-span-2">
                          <Listbox value={type} onChange={setType}>
                            {({ open }) => (
                              <>
                                <div className="mt-1 relative">
                                  <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-600 focus:border-cyan-600 sm:text-sm">
                                    <span className="block truncate capitalize">
                                      {type?.name ? type.name : "Select type"}
                                    </span>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                      <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                      {productTypes.map((type) => (
                                        <Listbox.Option
                                          key={type.id}
                                          className={({ active }) =>
                                            classNames(
                                              active
                                                ? "text-white bg-cyan-500"
                                                : "text-gray-900",
                                              "cursor-default select-none relative py-2 pl-3 pr-9"
                                            )
                                          }
                                          value={type}
                                        >
                                          {({ selected, active }) => (
                                            <>
                                              <span
                                                className={classNames(
                                                  selected
                                                    ? "font-semibold"
                                                    : "font-normal",
                                                  "block truncate"
                                                )}
                                              >
                                                {type.name}
                                              </span>

                                              {selected ? (
                                                <span
                                                  className={classNames(
                                                    active
                                                      ? "text-white"
                                                      : "text-cyan-500",
                                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </>
                            )}
                          </Listbox>
                        </div>
                      </div>

                      {/* status */}

                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="status"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Status
                          </label>
                        </div>

                        <div className="sm:col-span-2">
                          <Switch.Group>
                            <div className="flex items-center">
                              <Switch
                                checked={productStatus}
                                onChange={setProductStatus}
                                className={`${
                                  productStatus ? "bg-cyan-500" : "bg-gray-200"
                                } relative inline-flex items-center h-10 w-full rounded-md  transform transition ease-in-out duration-1900`}
                              >
                                <span className="sr-only">Enable</span>
                                <div
                                  className={`transform transition ease-in-out duration-1900 p-1 inline-block w-1/2 h-10 bg-white rounded-md 
                                ${
                                  productStatus
                                    ? "translate-x-full border-4 border-cyan-500 text-cyan-500 font-medium"
                                    : "translate-x-0  border-4 border-gray-200 text-gray-300 font-medium"
                                }
                              `}
                                >
                                  <p>{productStatus ? "Active" : "Inactive"}</p>
                                </div>
                              </Switch>
                            </div>
                          </Switch.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Action buttons */}
                  <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                    {createStatus === "" ? (
                      <div className="space-x-3 flex justify-end">
                        <button
                          type="button"
                          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-600"
                          onClick={onClickClose}
                        >
                          Cancel
                        </button>
                        <button
                          onClick={(e) => onClickCUpdateProduct(e)}
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-600"
                        >
                          Create
                        </button>
                      </div>
                    ) : (
                      <>
                        {" "}
                        <p className="text-md text-center text-gray-500">
                          {createStatus}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
