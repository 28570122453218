/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";

import { XMarkIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NotificationBanner({
  showNotification,
  closeNotification,
  bgColor,
  icon,
  description,
}) {
  return (
    <>
      <div
        aria-live="assertive"
        className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={showNotification}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={classNames(bgColor, "rounded-md p-4")}>
              <div className="flex">
                <div className="flex-shrink-0">{icon}</div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-600">
                    {description}
                  </p>
                </div>
                <div className="ml-auto pl-3">
                  <div className="-mx-1.5 -my-1.5">
                    <button
                      onClick={closeNotification}
                      type="button"
                      className="inline-flex bg-transparent rounded-md p-1.5 text-black hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-50"
                    >
                      <span className="sr-only">Dismiss</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
