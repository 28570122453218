import {
  Bars3BottomLeftIcon
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../context/authUserProvider";
import DhiraaguLogo from "../../assets/logo-v2.svg";
import { Sidebar } from "components";
import {
  collection, getDocs,
  getFirestore, query,
  where
} from "firebase/firestore";

const PrivateRoute = ({ allowedRoles }) => {
  const { authUser, loading } = useAuth();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userData, setUserData] = useState();
  const [currentUserLoading, setCurrentUserLoading] = useState(true);

  const onclickSidebarOpen = () => {
    setSidebarOpen(true);
  };

  const onclickSidebarClose = () => {
    setSidebarOpen(false);
  };

  useEffect(() => {
    async function fetchUserData() {
      if (authUser) {
        const db = getFirestore();

        const q = query(
          collection(db, "ecousers"),
          where("userId", "==", authUser?.uid)
        );

        const querySnapshot = await getDocs(q);
        var userData = {};
        querySnapshot.forEach((doc) => {
          userData = doc.data();
        });
        setUserData(userData);
        setCurrentUserLoading(false);
      }
    }

    fetchUserData();
  }, [authUser, allowedRoles]);

  return currentUserLoading && allowedRoles ? (
    <></>
  ) : allowedRoles?.includes(userData?.role) ? (
    <>
      <div className="flex flex-1 flex-col lg:hidden">
        <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
          <button
            type="button"
            className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
          </button>

          <div className="flex items-center justify-end px-4">
              <img className="h-8 w-auto" src={DhiraaguLogo} alt="Logo" />
         
            </div>

        </div>
      </div>
      <Sidebar sidebarClose={onclickSidebarClose} sidebarOpen={sidebarOpen} />
      <Outlet />
    </>
  ) : !allowedRoles?.includes(userData?.role) ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
