import { Dialog, Listbox, Switch, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  PlusIcon as PlusIconMini,
} from "@heroicons/react/20/solid";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  updateDoc,
  writeBatch,
} from "firebase/firestore";

import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authUserProvider";

import { getStorage } from "firebase/storage";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function EditUser({
  onClickOpenProductEditor,
  onClickCloseProductEditor,
  user,
}) {
  let navigate = useNavigate();
  const { authUser } = useAuth();
  const storage = getStorage();
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [productName, setProductName] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [partNumber, setPartNumber] = useState("");
  const [productStatus, setProductStatus] = useState(true);
  const [createStatus, setCreateStatus] = useState("");

  const onClickClose = () => {
    setImage(null);
    setImagePreview(null);
    onClickCloseProductEditor();
  };

  const services = [
    { id: 1, name: "Fire service", serviceId: "fire" },
    { id: 2, name: "LBS", serviceId: "location" },
    { id: 3, name: "Booking", serviceId: "booking" },
    { id: 4, name: "Trip Management", serviceId: "trips" },
    { id: 5, name: "Intruder ", serviceId: "intruder" },
    { id: 6, name: "Operations ", serviceId: "operations" },
    { id: 7, name: "Buggy kiosk", serviceId: "buggyKiosk" },
    { id: 8, name: "Audio service", serviceId: "audio" },
    { id: 9, name: "Usecase", serviceId: "usecase" },
    { id: 10, name: "Settings", serviceId: "settings" },
   
  ];

  const roleType = [
    { id: 1, name: "Owner" },
    { id: 2, name: "Editor" },
    { id: 3, name: "Viewer" },
  ];

  const [selectedService, setSelectedService] = useState(services[0]);
  const [selectedRole, setSelectedRole] = useState(roleType[1]);
  const [givenRoles, setGivenRoles] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});
  const [enableUpdate, setEnableUpdate] = useState(false);

  const addRoles = (e) => {
    e.preventDefault();
    setEnableUpdate(true);
    const newRole = {
      name: selectedService.name,
      handle: selectedRole.name,
      serviceId: selectedService.serviceId,
    };
    //check if role exists before adding
    const roleExists = givenRoles.some((role) => role.name === newRole.name);
    if (roleExists) {
      return;
    }
    setGivenRoles([...givenRoles, newRole]);
  };

  // const removeRoles = (e, ORole) => {
  //   e.preventDefault();
  //   const newRoles = givenRoles.filter((role) => role.name !== ORole.name);
  //   setGivenRoles(newRoles);
  // };

  React.useEffect(() => {
    //get all user projects from firestore
    if (onClickOpenProductEditor) {
      const db = getFirestore();
      const userRef = collection(db, `projects`);

      getDocs(userRef).then((querySnapshot) => {
        const userProjects = [];
        querySnapshot.forEach((project) => {
          userProjects.push(project.data());
        });
        setProjects(userProjects);
        setSelectedProject(userProjects[0]);
      });
    }
  }, [user, onClickOpenProductEditor]);

  React.useEffect(() => {
    const db = getFirestore();
    const roles = collection(
      db,
      `ecousers/${user.email}/projects/${selectedProject.projectId}/services`
    );
    onSnapshot(roles, (querySnapshot) => {
      const roles = [];
      querySnapshot.forEach((doc) => {
        const newRole = {
          name: doc.data().title,
          handle: doc.data().role,
          serviceId: doc.data().id,
        };
        roles.push(newRole);
      });
      setGivenRoles(roles);
    });
  }, [user, selectedProject]);

  const onClickDeleteItem = (e, item, givenRoles) => {
    e.preventDefault();

    //check selected project is not empty

    const db = getFirestore();
    const deleteRef = doc(
      db,
      `ecousers/${user.email}/projects/${selectedProject.projectId}/services`,
      item.serviceId
    );

    const serviceRef = doc(
      db,
      `ecousers/${user.email}/projects/${selectedProject.projectId}`
    );

    deleteDoc(deleteRef).then(() => {
      if (givenRoles.length === 1) {
        updateDoc(serviceRef, {
          services: false,
        });
      }
      console.log("Document successfully deleted!");
    });

  };

  const onClickCUpdateProduct = async (e) => {
    e.preventDefault();

    if (enableUpdate) {
      const db = getFirestore();

      const batch = writeBatch(db);

      const serviceRef = doc(
        db,
        `ecousers/${user.email}/projects/${selectedProject.projectId}`
      );
      // Set the value of 'NYC'
      const fireRef = doc(
        db,
        `ecousers/${user.email}/projects/${selectedProject.projectId}/services`,
        "fire"
      );
      const locationRef = doc(
        db,
        `ecousers/${user.email}/projects/${selectedProject.projectId}/services`,
        "location"
      );

      const buggyKioskRef = doc(
        db,
        `ecousers/${user.email}/projects/${selectedProject.projectId}/services`,
        "buggyKiosk"
      );
      const settingsRef = doc(
        db,
        `ecousers/${user.email}/projects/${selectedProject.projectId}/services`,
        "settings"
      );
      const audioRef = doc(
        db,
        `ecousers/${user.email}/projects/${selectedProject.projectId}/services`,
        "audio"
      );

      const usecaseRef = doc(
        db,
        `ecousers/${user.email}/projects/${selectedProject.projectId}/services`,
        "usecase"
      );

      const intruderRef = doc(
        db,
        `ecousers/${user.email}/projects/${selectedProject.projectId}/services`,
        "intruder"
      );

      const operationsRef = doc(
        db,
        `ecousers/${user.email}/projects/${selectedProject.projectId}/services`,
        "operations"
      );

      batch.set(serviceRef, {
        services: true,
        projectId: selectedProject.projectId,
        email: user.email,
      });

      givenRoles.forEach((role) => {
        if (role.serviceId === "fire") {
          batch.set(fireRef, {
            active: true,
            id: "fire",
            icon: "FireIcon",
            role: role.handle,
            items: [
              {
                active: true,
                order: 1,
                serviceId: "fire",
                slug: "home",
                title: "Home",
                icon: "HomeIcon",
              },
              {
                active: true,
                order: 2,
                serviceId: "fire",
                slug: "settings",
                title: "settings",
                description: "Manage users",
                icon: "Cog6ToothIcon",
              },
            ],
            order: 3,
            slug: "fire",
            title: "Fire service",
          });
        } else if (role.serviceId === "buggyKiosk") {
          batch.set(buggyKioskRef, {
            active: true,
            id: "buggyKiosk",
            icon: "buggyKioskIcon",
            role: role.handle,
            items: [
              {
                active: true,
                order: 1,
                serviceId: "buggyKiosk",
                slug: "home",
                title: "Home",
                icon: "HomeIcon",
              },
              {
                active: true,
                order: 2,
                serviceId: "buggyKiosk",
                slug: "settings",
                title: "settings",
                description: "Manage users",
                icon: "Cog6ToothIcon",
              },
            ],
            order: 3,
            slug: "buggyKiosk",
            title: "Buggy Kiosk",
          });
        } else if (role.serviceId === "location") {
          batch.set(locationRef, {
            active: true,
            id: "location",
            icon: "MapPinIcon",
            role: role.handle,
            items: [
              {
                active: true,
                order: 1,
                serviceId: "location",
                slug: "home",
                title: "Home",
                icon: "HomeIcon",
              },
              {
                active: true,
                order: 2,
                serviceId: "location",
                slug: "settings",
                title: "settings",
                description: "Manage users",
                icon: "Cog6ToothIcon",
              },
            ],
            order: 3,
            slug: "location",
            title: "Location services",
          });
        } else if (role.serviceId === "settings") {
          batch.set(settingsRef, {
            active: true,
            id: "settings",
            icon: "Cog6ToothIcon",
            role: role.handle,
            items: [
              {
                active: true,
                order: 1,
                serviceId: "settings",
                slug: "users",
                title: "Users",
                description: "Manage users",
                icon: "UsersIcon",
              },
            ],
            order: 3,
            slug: "settings",
            title: "Settings",
          });
        } else if (role.serviceId === "operations") {
          batch.set(operationsRef, {
            active: true,
            id: "operations",
            icon: "Cog6ToothIcon",
            role: role.handle,
            items: [
              {
                active: true,
                order: 1,
                serviceId: "operations",
                slug: "home",
                title: "Home",
                description: "Manage home",
                icon: "HomeIcon",
              },
              {
                active: true,
                order: 2,
                serviceId: "operations",
                slug: "settings",
                title: "settings",
                description: "Manage users",
                icon: "Cog6ToothIcon",
              },
            ],
            order: 3,
            slug: "operations",
            title: "Operations",
          });
        }
        else if (role.serviceId === "audio") {
          batch.set(audioRef, {
            active: true,
            id: "audio",
            icon: "SpeakerWaveIcon",
            role: role.handle,
            items: [
              {
                active: true,
                order: 1,
                serviceId: "audio",
                slug: "home",
                title: "Home",
                icon: "HomeIcon",
              },
              {
                active: true,
                order: 2,
                serviceId: "audio",
                slug: "settings",
                title: "settings",
                description: "Manage users",
                icon: "Cog6ToothIcon",
              },
            ],
            order: 3,
            slug: "audio",
            title: "Audio service",
          });
        } else if (role.serviceId === "intruder") {
          batch.set(intruderRef, {
            active: true,
            id: "intruder",
            icon: "SignalIcon",
            role: role.handle,
            items: [
              {
                active: true,
                order: 1,
                serviceId: "intruder",
                slug: "home",
                title: "Home",
                icon: "HomeIcon",
              },
              {
                active: true,
                order: 2,
                serviceId: "intruder",
                slug: "settings",
                title: "settings",
                description: "Manage users",
                icon: "Cog6ToothIcon",
              },
            ],
            order: 3,
            slug: "intruder",
            title: "Intruder Detection",
          });
        } else if (role.serviceId === "usecase") {
          batch.set(usecaseRef, {
            active: true,
            id: "usecase",
            icon: "CodeBracketSquareIcon",
            role: role.handle,
            items: [
              {
                active: true,
                order: 1,
                serviceId: "usecase",
                slug: "home",
                title: "Home",
                icon: "HomeIcon",
              },
              {
                active: true,
                order: 2,
                serviceId: "usecase",
                slug: "settings",
                title: "settings",
                description: "Manage users",
                icon: "Cog6ToothIcon",
              },
            ],
            order: 3,
            slug: "usecase",
            title: "Usecase service",
          });
        }
      });

      // Commit the batch
      await batch.commit();
      onClickCloseProductEditor();
    } else {
      onClickCloseProductEditor();
    }
  };

  return (
    <Transition.Root show={onClickOpenProductEditor} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={onClickCloseProductEditor}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="px-4 py-6 bg-gray-50 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Edit User
                          </Dialog.Title>
                          <p className="text-sm text-gray-500">{user.email}</p>
                        </div>
                        <div className="h-7 flex items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={onClickClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}
                    <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                      {/* acc number */}
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="reg-number"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            First name
                          </label>
                        </div>
                        <div className="sm:col-span-3">
                          <input
                            disabled
                            onChange={(e) => setProductName(e.target.value)}
                            value={user.firstname}
                            type="text"
                            name="acc-number"
                            id="acc-number"
                            className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      {/* model number */}
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="reg-number"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Last Name
                          </label>
                        </div>
                        <div className="sm:col-span-3">
                          <input
                            disabled
                            onChange={(e) => setModelNumber(e.target.value)}
                            value={user.lastName}
                            type="text"
                            name="acc-number"
                            id="acc-number"
                            className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      {/* part number */}
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="reg-number"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Phone number
                          </label>
                        </div>
                        <div className="sm:col-span-3">
                          <input
                            disabled
                            onChange={(e) => setPartNumber(e.target.value)}
                            value={user.phoneNumber}
                            type="text"
                            name="acc-number"
                            id="acc-number"
                            className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      {/* main Role */}
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="reg-number"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Main Role
                          </label>
                        </div>
                        <div className="sm:col-span-3">
                          <input
                            disabled
                            defaultChecked={user.role === "admin"}
                            type="checkbox"
                            value={user.role === "admin"}
                            name="main-role-check"
                            id="main-role-check"
                            className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:pt2"
                          />
                          <label
                            htmlFor={"main-role-check"}
                            className="ml-3 text-sm font-medium text-gray-900"
                          >
                            Admin
                            <span className="ml-1 text-xs font-medium text-gray-900">
                              {" "}
                              - Gives access to all services
                            </span>
                          </label>
                        </div>
                      </div>

                      {/* status */}

                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="status"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Status
                          </label>
                        </div>

                        <div className="sm:col-span-3">
                          <Switch.Group>
                            <div className="flex items-center">
                              <Switch
                                disabled
                                checked={productStatus}
                                onChange={setProductStatus}
                                className={`${productStatus ? "bg-cyan-500" : "bg-gray-200"
                                  } relative inline-flex items-center h-10 w-full rounded-md  transform transition ease-in-out duration-1900`}
                              >
                                <span className="sr-only">Enable</span>
                                <div
                                  className={`transform transition ease-in-out duration-1900 p-1 inline-block w-1/2 h-10 bg-white rounded-md 
                                ${productStatus
                                      ? "translate-x-full border-4 border-cyan-500 text-cyan-500 font-medium"
                                      : "translate-x-0  border-4 border-gray-200 text-gray-300 font-medium"
                                    }
                              `}
                                >
                                  <p>{productStatus ? "Active" : "Inactive"}</p>
                                </div>
                              </Switch>
                            </div>
                          </Switch.Group>
                        </div>
                      </div>

                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-3 sm:px-6 sm:py-5">
                        <Listbox
                          value={selectedProject}
                          onChange={setSelectedProject}
                        >
                          {({ open }) => (
                            <>
                              <Listbox.Label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                                Select Project By ID
                              </Listbox.Label>
                              <div className="relative mt-1 sm:col-span-3">
                                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                  <span className="block truncate">
                                    {selectedProject.projectName}
                                  </span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon
                                      className="h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {projects.map((service) => (
                                      <Listbox.Option
                                        key={service.projectId}
                                        className={({ active }) =>
                                          classNames(
                                            active
                                              ? "bg-indigo-600 text-white"
                                              : "text-gray-900",
                                            "relative cursor-default select-none py-2 pl-3 pr-9"
                                          )
                                        }
                                        value={service}
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <span
                                              className={classNames(
                                                selected
                                                  ? "font-semibold"
                                                  : "font-normal",
                                                "block truncate"
                                              )}
                                            >
                                              {service.projectName}
                                            </span>

                                            {selected ? (
                                              <span
                                                className={classNames(
                                                  active
                                                    ? "text-white"
                                                    : "text-indigo-600",
                                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                                )}
                                              >
                                                <CheckIcon
                                                  className="h-5 w-5"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                      </div>

                      {/* given roles */}

                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="given-roles"
                            className="text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Given roles
                          </label>
                        </div>

                        <div className="sm:col-span-3">
                          <div className="mt-6 flow-root">
                            <ul
                              role="list"
                              className="-my-5 divide-y divide-gray-200"
                            >
                              {givenRoles.length !== 0 ? (
                                givenRoles.map((roles) => (
                                  <li key={roles.handle} className="py-4">
                                    <div className="flex items-center space-x-4">
                                      <div className="min-w-0 flex-1">
                                        <p className="truncate text-sm font-medium text-gray-900">
                                          {roles.name}
                                        </p>
                                        <p className="truncate text-sm text-gray-500">
                                          {"@" + roles.handle}
                                        </p>
                                      </div>
                                      <div>
                                        <button
                                          onClick={(e) => {
                                            onClickDeleteItem(
                                              e,
                                              roles,
                                              givenRoles
                                            );
                                          }}
                                          className="inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5 py-0.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50"
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </li>
                                ))
                              ) : (
                                <p className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-1">
                                  No roles assigned
                                </p>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-3 sm:px-6 sm:py-5">
                        <Listbox
                          value={selectedService}
                          onChange={setSelectedService}
                        >
                          {({ open }) => (
                            <>
                              <Listbox.Label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                                New Roles
                              </Listbox.Label>
                              <div className="relative mt-1">
                                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                  <span className="block truncate">
                                    {selectedService.name}
                                  </span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon
                                      className="h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {services.map((service) => (
                                      <Listbox.Option
                                        key={service.id}
                                        className={({ active }) =>
                                          classNames(
                                            active
                                              ? "bg-indigo-600 text-white"
                                              : "text-gray-900",
                                            "relative cursor-default select-none py-2 pl-3 pr-9"
                                          )
                                        }
                                        value={service}
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <span
                                              className={classNames(
                                                selected
                                                  ? "font-semibold"
                                                  : "font-normal",
                                                "block truncate"
                                              )}
                                            >
                                              {service.name}
                                            </span>

                                            {selected ? (
                                              <span
                                                className={classNames(
                                                  active
                                                    ? "text-white"
                                                    : "text-indigo-600",
                                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                                )}
                                              >
                                                <CheckIcon
                                                  className="h-5 w-5"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                        <Listbox
                          value={selectedRole}
                          onChange={setSelectedRole}
                        >
                          {({ open }) => (
                            <>
                              {/* <Listbox.Label className="block text-sm font-medium text-gray-700">Roles</Listbox.Label> */}
                              <div className="relative mt-1">
                                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                  <span className="block truncate">
                                    {selectedRole.name}
                                  </span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon
                                      className="h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {roleType.map((role) => (
                                      <Listbox.Option
                                        key={role.id}
                                        className={({ active }) =>
                                          classNames(
                                            active
                                              ? "bg-indigo-600 text-white"
                                              : "text-gray-900",
                                            "relative cursor-default select-none py-2 pl-3 pr-9"
                                          )
                                        }
                                        value={role}
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <span
                                              className={classNames(
                                                selected
                                                  ? "font-semibold"
                                                  : "font-normal",
                                                "block truncate"
                                              )}
                                            >
                                              {role.name}
                                            </span>

                                            {selected ? (
                                              <span
                                                className={classNames(
                                                  active
                                                    ? "text-white"
                                                    : "text-indigo-600",
                                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                                )}
                                              >
                                                <CheckIcon
                                                  className="h-5 w-5"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                        <button
                          type="button"
                          onClick={(e) => addRoles(e)}
                          className="inline-flex justify-center items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          <PlusIconMini
                            className="-ml-0.5 mr-2 h-4 w-4"
                            aria-hidden="true"
                          />
                          Add
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Action buttons */}
                  <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                    {createStatus === "" ? (
                      <div className="space-x-3 flex justify-end">
                        <button
                          type="button"
                          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-600"
                          onClick={onClickClose}
                        >
                          Cancel
                        </button>
                        <button
                          onClick={(e) => onClickCUpdateProduct(e)}
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-600"
                        >
                          Update
                        </button>
                      </div>
                    ) : (
                      <>
                        {" "}
                        <p className="text-md text-center text-gray-500">
                          {createStatus}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
