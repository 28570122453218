import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "routes/privateRoute";
import { AuthUserProvider } from "./context/authUserProvider";
import { Role } from "utils/roles";

const Login = lazy(() => import("views/login"));
const Console = lazy(() => import("views/console"));
const Clients = lazy(() => import("views/clients"));
const Inventory = lazy(() => import("views/inventory"));
const Users = lazy(() => import("views/users"));
const Reports = lazy(() => import("views/reports"));
const Packages = lazy(() => import("views/packages"));
const Services = lazy(() => import("views/services"));
const Discount = lazy(() => import("views/discount"));
const Orders = lazy(() => import("views/orders"));
const Config = lazy(() => import("views/config"));
const NoFoundComponent = lazy(() => import("views/notFound"));
const Unauthorized = lazy(() => import("views/unauthorized"));

const App = () => {
  return (
    <Suspense fallback={<div>loading</div>}>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/login" element={<Login />} />

        <Route
          element={
            <PrivateRoute
              allowedRoles={[
                Role.Procurement,
                Role.CreditControl,
                Role.Finance,
                Role.Sales,
                Role.DSS,
                Role.Admin,
                Role.Business,
                Role.User,
              ]}
            />
          }
        >
          <Route path="/console" element={<Console />} />
        </Route>

        <Route element={<PrivateRoute allowedRoles={[Role.Admin]} />}>
          <Route path="/console/config" element={<Config />} />
        </Route>

        <Route
          element={
            <PrivateRoute
              allowedRoles={[
                Role.Procurement,
                Role.CreditControl,
                Role.Finance,
                Role.Sales,
                Role.DSS,
                Role.Admin,
              ]}
            />
          }
        >
          <Route path="/console/reports" element={<Reports />} />
        </Route>

        <Route
          element={
            <PrivateRoute
              allowedRoles={[
                Role.Business,
                Role.Sales,
                Role.Procurement,
                Role.Admin,
              ]}
            />
          }
        >
          <Route path="/console/orders" element={<Orders />} />
        </Route>

        <Route
          element={
            <PrivateRoute
              allowedRoles={[Role.CreditControl, Role.Sales, Role.Admin]}
            />
          }
        >
          <Route path="/console/clients" element={<Clients />} />
        </Route>

        <Route
          element={<PrivateRoute allowedRoles={[Role.Business, Role.Admin]} />}
        >
          <Route path="/console/packages" element={<Packages />} />
        </Route>

        <Route
          element={<PrivateRoute allowedRoles={[Role.Business, Role.Admin]} />}
        >
          <Route path="/console/discount" element={<Discount />} />
        </Route>

        <Route
          element={
            <PrivateRoute allowedRoles={[Role.Procurement, Role.Admin]} />
          }
        >
          <Route path="/console/inventory" element={<Inventory />} />
        </Route>

        <Route
          element={
            <PrivateRoute allowedRoles={[Role.Procurement, Role.Admin]} />
          }
        >
          <Route path="/console/users" element={<Users />} />
        </Route>

        <Route
          element={
            <PrivateRoute allowedRoles={[Role.Admin]} />
          }
        >
          <Route path="/console/services" element={<Services />} />
        </Route>

        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="*" element={<NoFoundComponent />} />
      </Routes>
    </Suspense>
  );
};

export default App;
