import { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuth } from "../../context/authUserProvider";
import { Dialog, Transition, Listbox, Switch } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  LinkIcon,
  PlusSmallIcon,
  QuestionMarkCircleIcon,
  CheckIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/20/solid";
import RoleWrapper from "utils/roleWrapper";
import { Role } from "utils/roles";

import {
  getFirestore,
  collection,
  query,
  onSnapshot,
  updateDoc,
  where,
  getDoc,
  serverTimestamp,
  addDoc,
  setDoc,
  doc,
} from "firebase/firestore";

const statusStyles = {
  false: "bg-green-100 text-green-800",
  true: "bg-yellow-100 text-yellow-800",
};

const statusButtonStyles = {
  true: "bg-cyan-500 text-white hover:bg-cyan-600",
  false: "bg-transparent hover:bg-transparent",
};

const statusButtonInStyles = {
  false: "bg-cyan-500 text-white hover:bg-cyan-600",
  true: "bg-transparent hover:bg-transparent",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const statusData = [
  {
    id: 1,
    status: true,
  },

  {
    id: 2,
    status: false,
  },
];

export default function ClientEditor({
  onClickOpenEditor,
  onClickCloseEditor,
  project,
}) {
  let navigate = useNavigate();
  const { authUser } = useAuth();

  const [accountNumber, setAccountNumber] = useState("");
  const [plan, setPlan] = useState({});
  const [clientStatus, setClientStatus] = useState(false);

  // console.log(plan);

  const [planData, setPlanData] = useState([]);

  const [createStatus, setCreateStatus] = useState("");

  useEffect(() => {
    setAccountNumber(project.myAccountNo);
    setClientStatus(project.active);
    setPlan(project.planId);
  }, [project]);

  useEffect(() => {
    async function fetchPlanData() {
      const db = getFirestore();
      const q = query(collection(db, "plans"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        var planData = [];
        querySnapshot.forEach((doc) => {
          planData.push(doc.data());
        });
        setPlanData(planData);
      });
    }

    fetchPlanData();
  }, []);

  function getPlanSource(planId) {
    var plan = planData.find((plan) => plan.id === planId);
    return plan?.source;
  }

  const onClickUpdateClient = async (e) => {
    e.preventDefault();
    const db = getFirestore();
    // const newCompanyRef = doc(collection(db, "projects"));

    if (accountNumber !== "" && Object.keys(plan).length !== 0) {
      setCreateStatus("Updating client...");
      await updateDoc(doc(db, "projects", project.projectId), {
        projectUpdatedBy: authUser.email,
        projectUpdatedAt: serverTimestamp(),
        myAccountNo: accountNumber,
        planId: plan?.id ? plan?.id : project.planId,
        active: clientStatus,
      })
        .catch((error) => {
          console.log(error.message);
        })
        .then(() => {
          setCreateStatus("Client updated");
        })
        .then(() => {
          setTimeout(() => {
            onClickCloseEditor();
          }, 3000);
        })
        .then(() => {
          setTimeout(() => {
            setCreateStatus("");
          }, 4000);
        });
    } else {
      console.log("failed");
    }
  };

  return (
    <Transition.Root show={onClickOpenEditor} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={onClickCloseEditor}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="px-4 py-6 bg-gray-50 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Edit client
                          </Dialog.Title>
                          <p className="text-sm text-gray-500">
                            {project.projectName}
                          </p>
                        </div>
                        <div className="h-7 flex items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={onClickCloseEditor}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}
                    <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                      {/* acc number */}
                      <RoleWrapper allowedRoles={[Role.Admin, Role.Sales]}>
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="reg-number"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Account Number
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <input
                            onChange={(e) => setAccountNumber(e.target.value)}
                            value={accountNumber}
                            // defaultValue={projectData.myAccountNo}
                            type="text"
                            name="acc-number"
                            id="acc-number"
                            className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      {/* plan select */}

                      <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:px-6 sm:py-5">
                        <div>
                          <h3 className="text-sm font-medium text-gray-900">
                            Plan
                          </h3>
                        </div>
                        <div className="sm:col-span-2">
                          <Listbox value={plan} onChange={setPlan}>
                            {({ open }) => (
                              <>
                                <div className="mt-1 relative">
                                  <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-600 focus:border-cyan-600 sm:text-sm">
                                    <span className="block truncate capitalize">
                                      {getPlanSource(project?.planId)}
                                      {/* {plan?.displayName
                                        ? plan?.displayName
                                        : project?.planId} */}
                                    </span>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                      <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                      {planData.map((plan) => (
                                        <Listbox.Option
                                          key={plan.id}
                                          className={({ active }) =>
                                            classNames(
                                              active
                                                ? "text-white bg-cyan-500"
                                                : "text-gray-900",
                                              "cursor-default select-none relative py-2 pl-3 pr-9"
                                            )
                                          }
                                          value={plan}
                                        >
                                          {({ selected, active }) => (
                                            <>
                                              <span
                                                className={classNames(
                                                  selected
                                                    ? "font-semibold"
                                                    : "font-normal",
                                                  "block truncate"
                                                )}
                                              >
                                                {plan.source}
                                              </span>

                                              {selected ? (
                                                <span
                                                  className={classNames(
                                                    active
                                                      ? "text-white"
                                                      : "text-cyan-500",
                                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </>
                            )}
                          </Listbox>
                        </div>
                      </div>
                      </RoleWrapper>

                      {/* status */}

                      <RoleWrapper allowedRoles={[Role.Admin, Role.CreditControl]}>
                        <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="status"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Status
                            </label>
                          </div>

                          <div className="sm:col-span-2">
                            <Switch.Group>
                              <div className="flex items-center">
                                <Switch
                                  checked={clientStatus}
                                  onChange={setClientStatus}
                                  className={`${
                                    clientStatus ? "bg-cyan-500" : "bg-gray-200"
                                  } relative inline-flex items-center h-10 w-full rounded-md  transform transition ease-in-out duration-1900`}
                                >
                                  <span className="sr-only">Enable</span>
                                  <div
                                    className={`transform transition ease-in-out duration-1900 p-1 inline-block w-1/2 h-10 bg-white rounded-md 
                                ${
                                  clientStatus
                                    ? "translate-x-full border-4 border-cyan-500 text-cyan-500 font-medium"
                                    : "translate-x-0  border-4 border-gray-200 text-gray-300 font-medium"
                                }
                              `}
                                  >
                                    <p>
                                      {clientStatus ? "Active" : "Inactive"}
                                    </p>
                                  </div>
                                </Switch>
                              </div>
                            </Switch.Group>
                          </div>
                        </div>
                      </RoleWrapper>
                    </div>
                  </div>

                  {/* Action buttons */}
                  <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                    {createStatus === "" ? (
                      <div className="space-x-3 flex justify-end">
                        <button
                          type="button"
                          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-600"
                          onClick={onClickCloseEditor}
                        >
                          Cancel
                        </button>
                        <button
                          onClick={(e) => onClickUpdateClient(e)}
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-600"
                        >
                          Update
                        </button>
                      </div>
                    ) : (
                      <>
                        {" "}
                        <p className="text-md text-center text-gray-500">
                          {createStatus}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
