import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import { useAuth } from "../../context/authUserProvider";
import { AsignCard, CommentCard, CreateCommentCard } from "../../components";
import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
  addDoc,
  serverTimestamp,
  documentId,
  collection,
  collectionGroup,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
} from "firebase/firestore";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function OrderDetails({
  openOrderDetails,
  closeOrderDetails,
  Order,
}) {
  const { authUser } = useAuth();
  const [comment, setComment] = useState("");
  const [commentData, setCommentData] = useState([]);

  // console.log(Order.cartId);

  useEffect(() => {
    async function fetchCommentData() {
      const db = getFirestore();
      const q = query(collection(db, `orderRemarks/${Order.cartId}/log`));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        var commentData = [];
        querySnapshot.forEach((doc) => {
          commentData.push(doc.data());
        });
        setCommentData(commentData);
        // console.log(commentData);
      });
    }

    fetchCommentData();
  }, [Order]);

  async function onClickAddComment(e) {
    e.preventDefault();

    const db = getFirestore();
    if (comment !== "") {
      const newCityRef = doc(collection(db, "orderRemarks"));

      await setDoc(doc(db, "orderRemarks", Order.cartId), {
        id: Order.cartId,
      });

      await setDoc(
        doc(db, "orderRemarks", Order.cartId, "log", newCityRef.id),
        {
          id: newCityRef.id,
          cartId: Order.cartId,
          comment: comment,
          type: "comment",
          date: serverTimestamp(),
          person: { name: authUser.email, href: "#" },
        }
      );
      // setCommentTrack(nanoid());
    }
  }
  return (
    <Transition.Root show={openOrderDetails} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={closeOrderDetails}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="px-4 py-6 bg-gray-50 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Order Details
                          </Dialog.Title>
                          {/* <p className="text-sm text-gray-500">Order Items</p> */}
                        </div>
                        <div className="h-7 flex items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={closeOrderDetails}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}
                    <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                      {/* start of table */}

                      <div className="hidden sm:block">
                        <div className="max-w-full mx-auto">
                          <div className="flex flex-col">
                            <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden ">
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Name
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      QTY
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Rate
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                  {Order?.items?.map((customer) => (
                                    <tr
                                      key={customer.cartId}
                                      className="hover:bg-gray-100"
                                    >
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 ">
                                        {customer.productName}
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {customer.quantity}
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 ">
                                        {customer.productRate}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* end of table */}

                      {/* comment */}

                      {Order.paymentStatus === "Processed" ? (
                        <>
                          <div className="p-6">
                            {/* Activity feed*/}
                            {commentData && (
                              <div className="flow-root">
                                <ul role="list" className="-mb-8">
                                  {commentData
                                    ?.sort(function (a, b) {
                                      return b.date - a.date;
                                    })
                                    .map((item, itemIdx) => (
                                      <li key={itemIdx}>
                                        <div className="relative pb-8">
                                          {itemIdx !==
                                          commentData.length - 1 ? (
                                            <span
                                              className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                                              aria-hidden="true"
                                            />
                                          ) : null}
                                          <div className="relative flex items-start space-x-3">
                                            {item.type === "comment" ? (
                                              <>
                                                <CommentCard
                                                  avatar={`https://avatars.dicebear.com/api/initials/${item.person.name}.svg`}
                                                  href={item.person.href}
                                                  name={item.person.name?.substring(
                                                    0,
                                                    item.person.name?.indexOf(
                                                      "@"
                                                    )
                                                  )}
                                                  date={
                                                    item.date === null
                                                      ? ""
                                                      : format(
                                                          item.date.toDate(),
                                                          "MMM - do - yyyy"
                                                        )
                                                  }
                                                  comment={item.comment}
                                                />
                                              </>
                                            ) : item.type === "assignment" ? (
                                              <AsignCard
                                                assignorHref={item.person.href}
                                                assignorName={item.person.name}
                                                assigneeHref={
                                                  item.assigned.href
                                                }
                                                assigneeName={
                                                  item.assigned.name
                                                }
                                                assignedDate={item.date}
                                              />
                                            ) : (
                                              <></>
                                              // <TagCard
                                              //   item={item}
                                              //   personHref={item.person.href}
                                              //   personName={item.person.name}
                                              //   date={item.date}
                                              // />
                                            )}
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            )}

                            <CreateCommentCard
                              onClick={(e) => {
                                onClickAddComment(e);
                              }}
                              onChange={(e) => setComment(e.target.value)}
                              src={`https://avatars.dicebear.com/api/initials/${authUser.email}.svg`}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {/* end of comment */}
                    </div>
                  </div>

                  {/* Action buttons */}
                  <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                    <div className="space-x-3 flex justify-end">
                      <button
                        type="submit"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-500 hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
