import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthUserProvider } from "./context/authUserProvider";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthUserProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </AuthUserProvider>
    </BrowserRouter>
  </React.StrictMode>
);
