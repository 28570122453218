import React from "react";
import { UserCircleIcon as UserCircleIconSolid } from "@heroicons/react/20/solid";

function AsignCard({
  assignorHref,
  assignorName,
  assigneeHref,
  assigneeName,
  assignedDate,
}) {
  return (
    <>
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-gray-200 rounded-full ring-8 ring-gray-100 flex items-center justify-center">
            <UserCircleIconSolid
              className="h-5 w-5 text-gray-500"
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-1.5 ">
        <div className="text-sm text-gray-500">
          <a href={assignorHref} className="font-medium text-gray-900">
            {assignorName}
          </a>{" "}
          assigned{" "}
          <a href={assigneeHref} className="font-medium text-gray-900">
            {assigneeName}
          </a>{" "}
          <span className="whitespace-nowrap">{assignedDate}</span>
        </div>
      </div>{" "}
    </>
  );
}

export default AsignCard;
