import { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { useAuth } from "../context/authUserProvider";

const RoleWrapper = ({ allowedRoles, children }) => {
  const { authUser } = useAuth();
  const [userData, setUserData] = useState();
  const [currentUserLoading, setCurrentUserLoading] = useState(true);

  useEffect(() => {
    async function fetchUserData() {
      if (authUser) {
        const db = getFirestore();

        const q = query(
          collection(db, "ecousers"),
          where("userId", "==", authUser?.uid)
        );

        const querySnapshot = await getDocs(q);
        var userData = {};
        querySnapshot.forEach((doc) => {
          userData = doc.data();
        });
        setUserData(userData);
        setCurrentUserLoading(false);
      }
    }

    fetchUserData();
  }, [authUser, allowedRoles]);

  // console.log(allowedRoles?.includes(userData?.role));

  // console.log("user role", userData?.role);

  // console.log("allowed", allowedRoles);

  return currentUserLoading && allowedRoles ? (
    <></>
  ) : allowedRoles?.includes(userData?.role) ? (
    <>{children}</>
  ) : !allowedRoles?.includes(userData?.role) ? (
    <></>
  ) : (
    <></>
  );
};

export default RoleWrapper;
