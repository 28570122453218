import { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuth } from "../../context/authUserProvider";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import {
  LinkIcon,
  PlusSmallIcon,
  QuestionMarkCircleIcon,
  CheckIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/20/solid";

import {
  getFirestore,
  collection,
  query,
  onSnapshot,
  where,
  getDoc,
  serverTimestamp,
  updateDoc,
  addDoc,
  setDoc,
  doc,
} from "firebase/firestore";

const statusStyles = {
  false: "bg-green-100 text-green-800",
  true: "bg-yellow-100 text-yellow-800",
};

const statusButtonStyles = {
  true: "bg-cyan-500 text-white hover:bg-cyan-600",
  false: "bg-transparent hover:bg-transparent",
};

const statusButtonInStyles = {
  false: "bg-cyan-500 text-white hover:bg-cyan-600",
  true: "bg-transparent hover:bg-transparent",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const statusData = [
  {
    id: 1,
    status: true,
  },

  {
    id: 2,
    status: false,
  },
];

export default function SalesConfig({
  onClickOpenSalesConfig,
  onClickCloseSalesConfig,
  selectedPlan,
}) {
  let navigate = useNavigate();
  const { authUser } = useAuth();
  const location = useLocation();

  var parts = location.pathname.split("/");
  var path = parts[2];

  const [email, setEmail] = useState("");

  const [createStatus, setCreateStatus] = useState("");

  const onClickUpdateEmail = async (e) => {
    e.preventDefault();
    const db = getFirestore();

    if (email !== "") {
      setCreateStatus("Updating email...");
      try {
        await updateDoc(doc(db, "salesEmails", "emails"), {
          email1: email,
        })
          .catch((error) => {
            console.log(error.message);
          })
          .then(() => {
            setCreateStatus("Email updated");
          })
          .then(() => {
            setTimeout(() => {
              onClickCloseSalesConfig();
            }, 3000);
          })
          .then(() => {
            setTimeout(() => {
              setCreateStatus("");
            }, 4000);
          });
      } catch (error) {
        console.log(error.code);
        setCreateStatus(error.message);
      }
    }
  };

  return (
    <Transition.Root show={onClickOpenSalesConfig} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={onClickCloseSalesConfig}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="px-4 py-6 bg-gray-50 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Update sales email
                          </Dialog.Title>
                          <p className="text-sm text-gray-500">
                            {/* {project.projectName} */}
                          </p>
                        </div>
                        <div className="h-7 flex items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={onClickCloseSalesConfig}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}
                    <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                      <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:px-6 sm:py-5">
                        <div>
                          <h3 className="text-sm font-medium text-gray-900">
                            Email
                          </h3>
                        </div>
                        <div className="sm:col-span-2">
                          {/* email input */}
                          <input
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            type="text"
                            name="email"
                            id="email"
                            className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Action buttons */}
                  <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                    {createStatus === "" ? (
                      <div className="space-x-3 flex justify-end">
                        <button
                          type="button"
                          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-600"
                          onClick={onClickCloseSalesConfig}
                        >
                          Cancel
                        </button>
                        <button
                          onClick={(e) => onClickUpdateEmail(e)}
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-600"
                        >
                          Update email
                        </button>
                      </div>
                    ) : (
                      <>
                        {" "}
                        <p className="text-md text-center text-gray-500">
                          {createStatus}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
