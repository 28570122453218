import { Fragment, useState, useEffect, forwardRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuth } from "../../context/authUserProvider";
import { Dialog, Transition, Listbox, Switch } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { format, addDays } from "date-fns";

import {
  getFirestore,
  collection,
  query,
  onSnapshot,
  where,
  getDoc,
  serverTimestamp,
  addDoc,
  setDoc,
  updateDoc,
  doc,
  collectionGroup,
} from "firebase/firestore";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ButtonInput = forwardRef(({ value, onClick }, ref) => (
  <button
    onClick={onClick}
    ref={ref}
    type="button"
    className="inline-flex justify-start w-full px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500"
  >
    {format(new Date(value), "dd MMMM yyyy")}
  </button>
));

export default function CreateDiscountCode({
  onClickOpenCreateDiscountCode,
  onClickCloseCreateDiscountCode,
}) {
  let navigate = useNavigate();
  const { authUser } = useAuth();

  const startingDate = addDays(new Date(), 1)

  const [startDate, setStartDate] = useState(addDays(new Date(), 1));
  const [endDate, setEndDate] = useState(new Date().setMonth(startingDate.getMonth())
    // new Date().setMonth(startDate.getMonth())
  );

  useEffect(() => {
    if (startDate > endDate) setStartDate(endDate);
  }, [endDate]);

  useEffect(() => {
    if (startDate > endDate) setEndDate(startDate);
  }, [startDate]);

  const [name, setName] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [percent, setPercent] = useState(0);

  

  const [createStatus, setCreateStatus] = useState("");

  const onClickCreateDiscountCode = async (e) => {
    e.preventDefault();
    const db = getFirestore();
    const newCodeRef = doc(collection(db, "discountCodes"));

    if (name !== "" && discountCode !== "" && percent !== "") {
      setCreateStatus("Creating dicount code...");
      await setDoc(doc(db, "discountCodes", newCodeRef.id), {
        id: newCodeRef.id,
        createdBy: authUser.email,
        createdAt: serverTimestamp(),
        expiryDate: new Date(endDate),
        name: name,
        discountCode: discountCode,
        percent: percent,
      })
        .then(() => {
          setCreateStatus("Discount code created");
        })
        .then(() => {
          setTimeout(() => {
            onClickCloseCreateDiscountCode();
          }, 3000);
        })
        .then(() => {
          setTimeout(() => {
            setCreateStatus("");
          }, 4000);
        });
    } else {
      console.log("failed");
    }
  };

  return (
    <Transition.Root show={onClickOpenCreateDiscountCode} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={onClickCloseCreateDiscountCode}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="px-4 py-6 bg-gray-50 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Create Discount Code
                          </Dialog.Title>
                          <p className="text-sm text-gray-500">
                            Fill in to create discount code
                          </p>
                        </div>
                        <div className="h-7 flex items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={onClickCloseCreateDiscountCode}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}
                    <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                      {/* name */}
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="discount-name"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Name
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <input
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            type="text"
                            name="discount-name"
                            id="discount-name"
                            className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      {/* code */}
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="dicount-code"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Discount code
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <input
                            onChange={(e) => setDiscountCode(e.target.value)}
                            value={discountCode}
                            type="text"
                            name="dicount-code"
                            id="dicount-code"
                            className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      {/* percent */}
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="percent"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Percent
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              onChange={(e) => setPercent(e.target.value)}
                              value={percent}
                              type="number"
                              name="percent"
                              id="percent"
                              className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <span
                                className="text-gray-500 sm:text-sm"
                                id="price-currency"
                              >
                                %
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* expiry date */}
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="percent"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Expiry date
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="relative ">
                              <DatePicker
                                minDate={addDays(new Date(), 1)}
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                nextMonthButtonLabel=">"
                                previousMonthButtonLabel="<"
                                popperClassName="react-datepicker-right"
                                customInput={<ButtonInput />}
                                renderCustomHeader={({
                                  date,
                                  decreaseMonth,
                                  increaseMonth,
                                  prevMonthButtonDisabled,
                                  nextMonthButtonDisabled,
                                }) => (
                                  <div className="flex items-center justify-between px-2 py-2">
                                    <span className="text-lg text-gray-700">
                                      {format(date, "MMMM yyyy")}
                                    </span>

                                    <div className="space-x-2">
                                      <button
                                        onClick={decreaseMonth}
                                        disabled={prevMonthButtonDisabled}
                                        type="button"
                                        className={`
                                            ${
                                              prevMonthButtonDisabled &&
                                              "cursor-not-allowed opacity-50"
                                            }
                                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                                        `}
                                      >
                                        <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
                                      </button>

                                      <button
                                        onClick={increaseMonth}
                                        disabled={nextMonthButtonDisabled}
                                        type="button"
                                        className={`
                                            ${
                                              nextMonthButtonDisabled &&
                                              "cursor-not-allowed opacity-50"
                                            }
                                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                                        `}
                                      >
                                        <ChevronRightIcon className="w-5 h-5 text-gray-600" />
                                      </button>
                                    </div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Action buttons */}
                  <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                    {createStatus === "" ? (
                      <div className="space-x-3 flex justify-end">
                        <button
                          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-600"
                          onClick={onClickCloseCreateDiscountCode}
                        >
                          Cancel
                        </button>
                        <button
                          onClick={onClickCreateDiscountCode}
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-600"
                        >
                          Create
                        </button>
                      </div>
                    ) : (
                      <>
                        {" "}
                        <p className="text-md text-center text-gray-500">
                          {createStatus}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
