import React from "react";

function PageHeader({ title, button1, button2 }) {
  return (
    <div className="lg:pl-64 flex flex-col flex-1 ">
      {/* <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8"> */}
      <div className="md:flex md:items-center md:justify-between px-4 sm:px-6 lg:px-8 mt-8">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {title}
          </h2>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          {button1}
          {button2}
          {/* <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Edit
            </button> */}
          {/* <button
              type="button"
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Publish
            </button> */}
        </div>
      </div>
    </div>
    // </div>
  );
}

export default PageHeader;
