import { Dialog, Transition } from "@headlessui/react";
import {
  ClipboardDocumentListIcon,
  DocumentChartBarIcon,
  DocumentTextIcon,
  GiftIcon,
  SwatchIcon,
  UsersIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DhiraaguLogo from "../../assets/dhiraagu-logo-white.svg";
import { useAuth } from "../../context/authUserProvider";

const navigation = [
  // {
  //   name: "Dashboard",
  //   href: "/dashboard",
  //   icon: PresentationChartBarIcon,
  // },
  // <RoleWrapper allowedRoles={[Role.Admin]} children={{name: "Clients", href: "/console/clients", icon: DocumentTextIcon }}/>,
  { name: "Clients", href: "/console/clients", path: "clients", icon: DocumentTextIcon },
  { name: "Users", href: "/console/users", path: "users", icon: UsersIcon },
   { name: "Services", href: "/console/services",path: "services", icon: ClipboardDocumentListIcon },
  { name: "Products", href: "/console/inventory", path: "inventory", icon: UsersIcon },
  { name: "Packages", href: "/console/packages", path: "packages", icon: SwatchIcon },
  { name: "Discount", href: "/console/discount", path: "discount", icon: GiftIcon },
  { name: "Orders", href: "/console/orders", path: "orders", icon: ClipboardDocumentListIcon },
  // { name: "Config", href: "/console/config", icon: CogIcon },
  { name: "Reports", href: "/console/reports", path: "reports", icon: DocumentChartBarIcon },
  // { name: "Regions", href: "/regions", icon: GlobeAmericasIcon },
  // { name: "Reports", href: "/reports", icon: NewspaperIcon },

  // { name: "Inventory", href: "/inventory", icon: Squares2X2Icon, current: false },
];

// console.log(navigation);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Sidebar({ sidebarOpen, sidebarClose }) {
  let navigate = useNavigate();
  const { authUser, logOut } = useAuth();
  const location = useLocation();

  // console.log(location.pathname);
  var parts = location.pathname.split("/");

  var path = parts[2];

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={sidebarClose}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={sidebarClose}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-6 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img className="h-8 w-auto" src={DhiraaguLogo} alt="Logo" />
                </div>
                <nav className="mt-5 px-2 space-y-1">

                  {navigation.map((item) => (

                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        `${path}` === item.path
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          `${path}` === item.path
                            ? "text-gray-500"
                            : "text-gray-400 group-hover:text-gray-500",
                          "mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>

                  ))}
                </nav>
              </div>
              <div className="flex-shrink-0 flex bg-gray-700 p-4">
                <div className="flex-shrink-0 w-full group block">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-9 w-9 rounded-full"
                        src={`https://avatars.dicebear.com/api/initials/${authUser?.email}.svg`}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-xs font-medium text-white">
                        {" "}
                        {authUser?.email}
                      </p>
                      <button
                        onClick={logOut}
                        className="text-sm font-medium text-gray-300 group-hover:text-gray-200"
                      >
                        Sign out
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">
            {/* Force sidebar to shrink to fit close icon */}

          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img className="h-8 w-auto" src={DhiraaguLogo} alt="Logo" />
              <p className="text-white font-semibold ml-2">Ecosys Admin</p>
            </div>
            <div className="mt-5 flex-grow flex flex-col">
              {/* <RoleWrapper allowedRoles={[Role.Business]}> */}
              <nav className="flex-1 px-2 space-y-8" aria-label="Sidebar">
                <div className="space-y-1">

                  {navigation.map((item) => (

                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        `${path}` === item.path
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          `${path}` === item.path
                            ? "text-gray-500"
                            : "text-gray-400 group-hover:text-gray-500",
                          "mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>

                  ))}
                </div>
              </nav>
              {/* </RoleWrapper> */}
            </div>
          </div>
          <div className="flex-shrink-0 flex bg-gray-700 p-4">
            <div className="flex-shrink-0 w-full group block">
              <div className="flex items-center">
                <div>
                  <img
                    className="inline-block h-9 w-9 rounded-full"
                    src={`https://avatars.dicebear.com/api/initials/${authUser?.email}.svg`}
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <p className="text-xs font-medium text-white">
                    {" "}
                    {authUser?.email}
                  </p>
                  <button
                    onClick={logOut}
                    className="text-sm font-medium text-gray-300 group-hover:text-gray-200"
                  >
                    Sign out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default Sidebar;
