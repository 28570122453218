import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { ClientEditor } from "..";

import {
  getFirestore,
  collection,
  query,
  onSnapshot,
} from "firebase/firestore";

import { BanknotesIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

const statusStyles = {
  true: "bg-green-100 text-green-800",
  false: "bg-yellow-100 text-yellow-800",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const servicereports = [
  {
    id: 1,
    slNo: "ECOB001",
    accNo: "BA11996767",
    serviceNo: "-",
    plan: "Ecosys Intro",
    billableAmt: "MVR 15037.58",
   
    
  },
];

function BillableReports() {
  let navigate = useNavigate();
  const [allCustomers, setAllCustomers] = useState([]);
  const [clients, setClients] = useState([]);

  const [openClientEditor, setOpenClientEditor] = useState(false);
  const [selectedProject, setSelectedProject] = useState({});

  const onClickOpenEditor = (e, customer) => {
    // e.preventDefault();
    setOpenClientEditor(true);
    setSelectedProject(customer);
  };

  const onClickCloseEditor = () => {
    setOpenClientEditor(false);
  };

  useEffect(() => {
    async function fetchCustomerData() {
      const db = getFirestore();
      const q = query(collection(db, "projects"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        var customerData = [];
        querySnapshot.forEach((doc) => {
          customerData.push(doc.data());
        });
        setAllCustomers(customerData);
      });
    }

    fetchCustomerData();
  }, []);

  function convertToCSV(objArray) {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";

    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line !== "") line += ",";

        line += array[i][index];
      }

      str += line + "\r\n";
    }

    return str;
  }

  function exportCSVFile(headers, items, fileTitle) {
    if (headers) {
      items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + ".csv" || "export.csv";

    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  function download() {
    var headers = {
      slNo: "SL NO", // remove commas to avoid errors
      accountNumber: "Account Number",
      serviceNumber: "Service Number",
      planName: "Plan Name",
      charge: "Charge",
    };

    var itemsNotFormatted = [
      {
        slNo: "ECOB001",
        accountNumber: "BA11996767",
        serviceNumber: "-",
        planName: "Ecosys Intro",
        charge: "MVR 15037.58",
      },
    ];

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        slNo: item.slNo, // remove commas to avoid errors,
        accountNumber: item.accountNumber,
        serviceNumber: item.serviceNumber,
        planNmae: item.planName,
        charge: item.charge,
        serviceStart: item.serviceStart,
        status: item.status,
        serviceEnd: item.serviceEnd,
      });
    });

    var fileTitle = "Billable Details Reports"; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  return (
    <div className="min-h-screen">
      {/* <PageHeader /> */}
      {/* <Meta title="customers" /> */}

      <div className="flex flex-col flex-1">
        <div className="mt-4">
          <div className="shadow sm:hidden">
            <ul
              role="list"
              className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden"
            >
              {servicereports?.map((customer) => (
                <li key={customer.id}>
                  <a
                    // href={`customers/customer/${customer.projectId}`}
                    className="block px-4 py-4 bg-white hover:bg-gray-50"
                  >
                    <span className="flex items-center space-x-4">
                      <span className="flex-1 flex space-x-2 truncate">
                        <BanknotesIcon
                          className="flex-shrink-0 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="flex flex-col text-gray-500 text-sm truncate">
                          <span className="truncate">
                            {customer.accNo}
                          </span>
                          <span>
                            <span className="text-gray-900 font-medium">
                              {customer.billableAmt}
                            </span>{" "}
                          </span>
                        </span>
                      </span>
                      <ChevronRightIcon
                        className="flex-shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </a>
                </li>
              ))}
            </ul>
            {/* <nav
              className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
              aria-label="Pagination"
            >
              <div className="flex-1 flex justify-between">
                <a
                  href="#"
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                >
                  Previous
                </a>
                <a
                  href="#"
                  className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                >
                  Next
                </a>
              </div>
            </nav> */}
          </div>
          {/* onClick={() =>
                                      router.push({
                                        pathname: `customers/customer/[id]`,
                                        query: { id: customer.customerId },
                                      })
                                    } */}

          {/* Activity table (small breakpoint and up) */}
          <div className="hidden sm:block">
            <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex flex-col mt-2">
                <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          SL number
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Account number
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Service number
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Plan name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Billable amount
                        </th>
                        
                        
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <span className="sr-only">Export</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {servicereports.map((customer) => (
                        <tr key={customer.id} className="hover:bg-gray-100">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
                            {customer.slNo}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {customer.accNo}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {customer.serviceNo}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
                            {customer.plan}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
                            {customer.billableAmt}
                          </td>
                         
                          
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <button
                                onClick={(e) => download()}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              Download
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* Pagination */}
                  <nav
                    className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                    aria-label="Pagination"
                  >
                    <div className="hidden sm:block">
                      <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">1</span> to{" "}
                        <span className="font-medium">
                          {servicereports?.length}
                        </span>
                      </p>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ClientEditor
        onClickOpenEditor={openClientEditor}
        onClickCloseEditor={onClickCloseEditor}
        project={selectedProject}
      />
    </div>
  );
}

export default BillableReports;
