export const Role = {
  Admin: "admin",
  User: "user",
  Business: "business",
  Sales: "sales",
  Finance: "finance",
  CreditControl: "creditControl",
  DSS: "dss",
  Procurement: "procurement",
};
