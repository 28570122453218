import { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuth } from "../../context/authUserProvider";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import {
  LinkIcon,
  PlusSmallIcon,
  QuestionMarkCircleIcon,
  CheckIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/20/solid";

import {
  getFirestore,
  collection,
  query,
  onSnapshot,
  where,
  getDoc,
  serverTimestamp,
  updateDoc,
  addDoc,
  setDoc,
  doc,
} from "firebase/firestore";

const statusStyles = {
  false: "bg-green-100 text-green-800",
  true: "bg-yellow-100 text-yellow-800",
};

const statusButtonStyles = {
  true: "bg-cyan-500 text-white hover:bg-cyan-600",
  false: "bg-transparent hover:bg-transparent",
};

const statusButtonInStyles = {
  false: "bg-cyan-500 text-white hover:bg-cyan-600",
  true: "bg-transparent hover:bg-transparent",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const statusData = [
  {
    id: 1,
    status: true,
  },

  {
    id: 2,
    status: false,
  },
];

export default function ApplyDiscount({
  onClickOpenApplyDiscount,
  onClickCloseApplyDiscount,
  selectedPlan,
}) {
  let navigate = useNavigate();
  const { authUser } = useAuth();
  const location = useLocation();

  // console.log(location.pathname);
  var parts = location.pathname.split("/");

  var path = parts[2];

  const [accountNumber, setAccountNumber] = useState("");
  const [plan, setPlan] = useState({});

  const [planData, setPlanData] = useState([]);

  const [createStatus, setCreateStatus] = useState("");

  //   useEffect(() => {
  //     setAccountNumber(project.myAccountNo);
  //   }, [project]);

  useEffect(() => {
    async function fetchPlanData() {
      const db = getFirestore();
      let timestamp = new Date();

      const q = query(
        collection(db, "discountCodes"),
        where("expiryDate", ">", timestamp)
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        var planData = [];
        querySnapshot.forEach((doc) => {
          planData.push(doc.data());
        });
        setPlanData(planData);
      });
    }

    fetchPlanData();
  }, []);

  const onClickAddDiscount = async (e) => {
    e.preventDefault();
    const db = getFirestore();

    if (Object.keys(plan).length !== 0) {
      setCreateStatus("Applying discount...");
      try {
        if (path === "packages") {
          await updateDoc(doc(db, "plans", selectedPlan.id), {
            discountAppliedBY: authUser.email,
            discountAddedAt: serverTimestamp(),
            discount: plan,
          })
            .catch((error) => {
              console.log(error.message);
            })
            .then(() => {
              setCreateStatus("Discount applied");
            })
            .then(() => {
              setTimeout(() => {
                onClickCloseApplyDiscount();
              }, 3000);
            })
            .then(() => {
              setTimeout(() => {
                setCreateStatus("");
              }, 4000);
            });
        } else if (path === "clients") {
          await updateDoc(doc(db, "projects", selectedPlan.projectId), {
            discountAppliedBY: authUser.email,
            discountAddedAt: serverTimestamp(),
            discount: plan,
          })
            .catch((error) => {
              console.log(error.message);
            })
            .then(() => {
              setCreateStatus("Discount applied");
            })
            .then(() => {
              setTimeout(() => {
                onClickCloseApplyDiscount();
              }, 3000);
            })
            .then(() => {
              setTimeout(() => {
                setCreateStatus("");
              }, 4000);
            });
        }
      } catch (error) {
        console.log(error.code);
        setCreateStatus(error.message);
      }
    }
  };

  return (
    <Transition.Root show={onClickOpenApplyDiscount} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={onClickCloseApplyDiscount}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="px-4 py-6 bg-gray-50 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Apply discount
                          </Dialog.Title>
                          <p className="text-sm text-gray-500">
                            {/* {project.projectName} */}
                          </p>
                        </div>
                        <div className="h-7 flex items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={onClickCloseApplyDiscount}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}
                    <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                      {/* acc number */}
                      {/* <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="reg-number"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Account Number
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <input
                            onChange={(e) => setAccountNumber(e.target.value)}
                            value={accountNumber}
                            // defaultValue={projectData.myAccountNo}
                            type="text"
                            name="acc-number"
                            id="acc-number"
                            className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                          />
                        </div>
                      </div> */}

                      {/* plan select */}

                      <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:px-6 sm:py-5">
                        <div>
                          <h3 className="text-sm font-medium text-gray-900">
                            Select discount code
                          </h3>
                        </div>
                        <div className="sm:col-span-2">
                          <Listbox value={plan} onChange={setPlan}>
                            {({ open }) => (
                              <>
                                <div className="mt-1 relative">
                                  <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-600 focus:border-cyan-600 sm:text-sm">
                                    <span className="truncate">
                                      {plan?.name}
                                    </span>
                                    <span className="ml-2 truncate text-gray-500">
                                      {plan?.discountCode}
                                    </span>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                      <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                      {planData.map((plan) => (
                                        <Listbox.Option
                                          key={plan.id}
                                          className={({ active }) =>
                                            classNames(
                                              active
                                                ? "text-white bg-cyan-500"
                                                : "text-gray-900",
                                              "cursor-default select-none relative py-2 pl-3 pr-9"
                                            )
                                          }
                                          value={plan}
                                        >
                                          {({ selected, active }) => (
                                            <>
                                              <span
                                                className={classNames(
                                                  selected
                                                    ? "font-semibold"
                                                    : "font-normal",
                                                  "truncate"
                                                )}
                                              >
                                                {plan.name}
                                              </span>
                                              <span
                                                className={classNames(
                                                  active
                                                    ? "text-indigo-200"
                                                    : "text-gray-500",
                                                  "ml-2 truncate"
                                                )}
                                              >
                                                {plan.discountCode}
                                              </span>

                                              {selected ? (
                                                <span
                                                  className={classNames(
                                                    active
                                                      ? "text-white"
                                                      : "text-cyan-500",
                                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </>
                            )}
                          </Listbox>
                        </div>
                      </div>

                      {/* Status */}

                      {Object.keys(plan).length !== 0 ? (
                        <>
                          <div className="p-8">
                            <h3 className="font-medium text-gray-900">
                              Information
                            </h3>
                            <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                              <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">Name</dt>
                                <dd className="text-gray-900">{plan?.name}</dd>
                              </div>
                              <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">Discount code</dt>
                                <dd className="text-gray-900">
                                  {plan?.discountCode}
                                </dd>
                              </div>
                              <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">
                                  Discount percent
                                </dt>
                                <dd className="text-gray-900">
                                  {plan?.percent} %
                                </dd>
                              </div>
                              <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">Created at</dt>
                                <dd className="text-gray-900">
                                  {plan.createdAt
                                    ? format(
                                        plan?.createdAt.toDate(),
                                        "MMM - do - yyyy"
                                      )
                                    : ""}
                                </dd>
                              </div>
                              <div className="py-3 flex justify-between text-sm font-medium">
                                <dt className="text-gray-500">Expiry date</dt>
                                <dd className="text-gray-900">
                                  {plan.expiryDate
                                    ? format(
                                        plan?.expiryDate.toDate(),
                                        "MMM - do - yyyy"
                                      )
                                    : ""}
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  {/* Action buttons */}
                  <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                    {createStatus === "" ? (
                      <div className="space-x-3 flex justify-end">
                        <button
                          type="button"
                          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-600"
                          onClick={onClickCloseApplyDiscount}
                        >
                          Cancel
                        </button>
                        <button
                          onClick={(e) => onClickAddDiscount(e)}
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-600"
                        >
                          Apply Discount
                        </button>
                      </div>
                    ) : (
                      <>
                        {" "}
                        <p className="text-md text-center text-gray-500">
                          {createStatus}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
