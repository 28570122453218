import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { HeartIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { PencilIcon, PlusSmallIcon } from "@heroicons/react/20/solid";
import { format } from "date-fns";

import { doc, deleteDoc, getFirestore } from "firebase/firestore";



export default function ProductDetails({
  onClickOpenProductDetails,
  onClickCloseProductDetails,
  product,
}) {

  const onClickDeleteProduct = async (e, id) => { 
    e.preventDefault();

    // console.log(id)
    const db = getFirestore();
    await deleteDoc(doc(db, "products", id));

    onClickCloseProductDetails()


  }


  return (
    <Transition.Root show={onClickOpenProductDetails} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={() => {}}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* <div className=" fixed inset-y-0 right-0 pl-10 max-w-full flex"> */}
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto relative w-96">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      type="button"
                      className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={onClickCloseProductDetails}
                    >
                      <span className="sr-only">Close panel</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full bg-white p-8 overflow-y-auto">
                  <div className="pb-16 space-y-6">
                    <div>
                      <div className="aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg">
                        <img
                          src={product.productImage}
                          alt=""
                          className="object-cover"
                        />
                      </div>
                      {/* <div className="block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden">
                        <img
                          src={product.productImage}
                          alt=""
                          className="object-cover"
                        />
                      </div> */}
                      <div className="mt-4 flex items-start justify-between">
                        <div>
                          <h2 className="text-lg font-medium text-gray-900">
                            <span className="sr-only">Details for </span>
                            {product.productName}
                          </h2>
                        </div>
                        {/* <button
                          type="button"
                          className="ml-4 h-8 w-8 bg-white rounded-full flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        >
                          <HeartIcon className="h-6 w-6" aria-hidden="true" />
                          <span className="sr-only">Favorite</span>
                        </button> */}
                      </div>
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900">Information</h3>
                      <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                        <div className="py-3 flex justify-between text-sm font-medium">
                          <dt className="text-gray-500">Model No.</dt>
                          <dd className="text-gray-900">
                            {product.modelNumer}
                          </dd>
                        </div>
                        <div className="py-3 flex justify-between text-sm font-medium">
                          <dt className="text-gray-500">Serial No.</dt>
                          <dd className="text-gray-900">
                            {product.serialNumber}
                          </dd>
                        </div>
                        <div className="py-3 flex justify-between text-sm font-medium">
                          <dt className="text-gray-500">MAC address</dt>
                          <dd className="text-gray-900">
                            {product.macAddress}
                          </dd>
                        </div>
                        <div className="py-3 flex justify-between text-sm font-medium">
                          <dt className="text-gray-500">Type</dt>
                          <dd className="text-gray-900">{product.type}</dd>
                        </div>
                        <div className="py-3 flex justify-between text-sm font-medium">
                          <dt className="text-gray-500">Rate</dt>
                          <dd className="text-gray-900">
                            {product.productRate}
                          </dd>
                        </div>
                        <div className="py-3 flex justify-between text-sm font-medium">
                          <dt className="text-gray-500">Quantity</dt>
                          <dd className="text-gray-900">{product.quantity}</dd>
                        </div>
                      </dl>
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900">Description</h3>
                      <div className="mt-2 flex items-center justify-between">
                        <p className="text-sm text-gray-500 italic">
                          Add a description to this image.
                        </p>
                        <button
                          type="button"
                          className="-mr-2 h-8 w-8 bg-white rounded-full flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        >
                          <PencilIcon className="h-5 w-5" aria-hidden="true" />
                          <span className="sr-only">Add description</span>
                        </button>
                      </div>
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900">Created by</h3>
                      <ul
                        role="list"
                        className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200"
                      >
                        <li className="py-3 flex justify-between items-center truncate">
                          {/* <div
                            className="absolute inset-0 group-hover:bg-gray-50"
                            aria-hidden="true"
                          /> */}
                          <div className="flex-1 flex items-center min-w-0 relative">
                            <span className="flex-shrink-0 inline-block relative">
                              <img
                                className="h-10 w-10 rounded-full"
                                src={`https://avatars.dicebear.com/api/initials/${product.createdBy}.svg`}
                                alt=""
                              />
                            </span>
                            <div className="ml-4 truncate">
                              <p className="text-sm font-medium text-gray-900 truncate">
                                {" "}
                                {product.createdBy}
                              </p>
                              <p className="text-sm text-gray-500 truncate">
                                {product.timestamp === undefined
                                  ? ""
                                  : format(
                                      product?.timestamp.toDate(),
                                      "MMM - do - yyyy"
                                    )}
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="flex">
                      <button
                        type="button"
                        onClick={(e) => onClickDeleteProduct(e, product.productId)}
                        className="flex-1 ml-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
