import React from "react";
import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/20/solid";

function CommentCard({ avatar, href, name, date, comment }) {
  return (
    <>
      <div className="relative">
        <img
          className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-gray-100"
          src={avatar}
          alt=""
        />

        <span className="absolute -bottom-0.5 -right-1 bg-gray-100 rounded-tl px-0.5 py-px">
          <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </div>
      <div className="min-w-0 flex-1">
        <div>
          <div className="text-sm">
            <a href={href} className="font-medium capitalize text-gray-900">
              {name}
            </a>
          </div>
          <p className="mt-0.5 text-sm text-gray-500">Commented {date}</p>
        </div>
        <div className="mt-2 text-sm text-gray-700">
          <p>{comment}</p>
        </div>
      </div>
    </>
  );
}

export default CommentCard;
