import { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuth } from "../../context/authUserProvider";
import { Dialog, Transition, Listbox, Switch } from "@headlessui/react";
import { XMarkIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import {
  LinkIcon,
  PlusSmallIcon,
  QuestionMarkCircleIcon,
  CheckIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/20/solid";

import {
  getFirestore,
  collection,
  query,
  onSnapshot,
  where,
  getDoc,
  serverTimestamp,
  addDoc,
  setDoc,
  updateDoc,
  doc,
  collectionGroup,
} from "firebase/firestore";

const statusStyles = {
  true: "bg-green-100 text-green-800",
  false: "bg-yellow-100 text-yellow-800",
};

const statusButtonStyles = {
  true: "bg-cyan-500 text-white hover:bg-cyan-600",
  false: "bg-transparent hover:bg-transparent",
};

const statusButtonInStyles = {
  false: "bg-cyan-500 text-white hover:bg-cyan-600",
  true: "bg-transparent hover:bg-transparent",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const typeData = [
  {
    id: 1,
    type: "per month",
  },

  {
    id: 2,
    type: "pre paid",
  },
];

export default function EditPackage({
  onClickOpenEditPackage,
  onClickCloseEditPackage,
  packageData,
}) {
  let navigate = useNavigate();
  const { authUser } = useAuth();

  const [packageName, setPackageName] = useState("");
  const [source, setSource] = useState("");
  const [price, setprice] = useState(0);
  const [setupFee, setSetupFee] = useState(0);
  const [type, setType] = useState({});
  const [geo, setGeo] = useState(0);
  const [usecase, setUsecase] = useState(0);
  const [noOfUsers, setNoOfUsers] = useState(0);
  const [noOfDevices, setNoOfDevices] = useState(0);
  const [packageStatus, setPackageStatus] = useState(false);

  const [createStatus, setCreateStatus] = useState("");

  useEffect(() => {
    setPackageName(packageData.displayName);
    setprice(packageData.price);
    setSetupFee(packageData.setupFee);
    setType(packageData.planType);
    setGeo(packageData.geofences);
    setUsecase(packageData.usecases);
    setNoOfUsers(packageData.users);
    setNoOfDevices(packageData.devices);
    setPackageStatus(packageData.active);
    setSource(packageData.source);
  }, [packageData]);

  const onClickUpdatePackage = async (e) => {
    e.preventDefault();
    const db = getFirestore();

    if (
      packageName !== "" &&
      price !== "" &&
      setupFee !== "" &&
      geo !== "" &&
      noOfUsers !== "" &&
      usecase !== "" &&
      noOfDevices !== "" &&
      Object.keys(type).length !== 0
    ) {
      setCreateStatus("Updating package...");
      try {
        await updateDoc(doc(db, "plans", packageData.id), {
          updatedBy: authUser.email,
          createdAt: serverTimestamp(),
          active: packageStatus,
          assurance: true,
          currency: "$",
          displayName: packageName,
          price: price,
          setupFee: setupFee,
          devices: noOfDevices,
          geofences: geo,
          usecases: usecase,
          users: noOfUsers,
          planType: type,
          recommend: "",
          recommended: false,
          sms: true,
          source: source,
          support: true,
          icon: "",
          no: 4,
        })
          .catch((error) => {
            console.log(error.message);
          })
          .then(() => {
            setCreateStatus("Package updated");
          })
          .then(() => {
            setTimeout(() => {
              onClickCloseEditPackage();
            }, 3000);
          })
          .then(() => {
            setTimeout(() => {
              setCreateStatus("");
            }, 4000);
          });
      
      } catch (error) {
        console.log(error.code)
          setCreateStatus(error.message);
      
      }
    }
     
  };

  return (
    <Transition.Root show={onClickOpenEditPackage} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={onClickCloseEditPackage}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <form onSubmit={(e) => onClickUpdatePackage(e)}>
                    <div className="flex-1">
                      {/* Header */}
                      <div className="px-4 py-6 bg-gray-50 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-lg font-medium text-gray-900">
                              Edit package
                            </Dialog.Title>
                            <p className="text-sm text-gray-500">
                              Fill in to edit package
                            </p>
                          </div>
                          <div className="h-7 flex items-center">
                            <button
                              type="button"
                              className="text-gray-400 hover:text-gray-500"
                              onClick={onClickCloseEditPackage}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Divider container */}
                      <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                        <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="package-name"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Package name
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              // onChange={onClickUpdatePackage}
                              onChange={(e) => setPackageName(e.target.value)}
                              value={packageName}
                              type="text"
                              name="displayName"
                              id="package-name"
                              className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                            />
                          </div>
                        </div>

                        {/* price */}

                        <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="price"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Price
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              onChange={(e) => setprice(e.target.valueAsNumber)}
                              value={price}
                              type="number"
                              name="price"
                              id="price"
                              className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                            />
                          </div>
                        </div>

                        {/* setup fee */}
                        <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="setup-fee"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Setup fee
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              onChange={(e) => setSetupFee(e.target.valueAsNumber)}
                              value={setupFee}
                              type="number"
                              name="setup-fee"
                              id="setup-fee"
                              className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                            />
                          </div>
                        </div>

                        {/* Type */}
                        <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:px-6 sm:py-5">
                          <div>
                            <h3 className="text-sm font-medium text-gray-900">
                              Type
                            </h3>
                          </div>
                          <div className="sm:col-span-2">
                            <Listbox value={type} onChange={setType}>
                              {({ open }) => (
                                <>
                                  <div className="mt-1 relative">
                                    <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-600 focus:border-cyan-600 sm:text-sm">
                                      <span className="block truncate capitalize">
                                        {type?.type
                                          ? type?.type
                                          : "Select type"}
                                      </span>
                                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <ChevronUpDownIcon
                                          className="h-5 w-5 text-gray-400"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </Listbox.Button>

                                    <Transition
                                      show={open}
                                      as={Fragment}
                                      leave="transition ease-in duration-100"
                                      leaveFrom="opacity-100"
                                      leaveTo="opacity-0"
                                    >
                                      <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                        {typeData.map((type) => (
                                          <Listbox.Option
                                            key={type.id}
                                            className={({ active }) =>
                                              classNames(
                                                active
                                                  ? "text-white bg-cyan-500"
                                                  : "text-gray-900",
                                                "cursor-default select-none relative py-2 pl-3 pr-9 capitalize"
                                              )
                                            }
                                            value={type}
                                          >
                                            {({ selected, active }) => (
                                              <>
                                                <span
                                                  className={classNames(
                                                    selected
                                                      ? "font-semibold"
                                                      : "font-normal",
                                                    "block truncate"
                                                  )}
                                                >
                                                  {type.type}
                                                </span>

                                                {selected ? (
                                                  <span
                                                    className={classNames(
                                                      active
                                                        ? "text-white"
                                                        : "text-cyan-500",
                                                      "absolute inset-y-0 right-0 flex items-center pr-4"
                                                    )}
                                                  >
                                                    <CheckIcon
                                                      className="h-5 w-5"
                                                      aria-hidden="true"
                                                    />
                                                  </span>
                                                ) : null}
                                              </>
                                            )}
                                          </Listbox.Option>
                                        ))}
                                      </Listbox.Options>
                                    </Transition>
                                  </div>
                                </>
                              )}
                            </Listbox>
                          </div>
                        </div>

                        {/* devices */}
                        <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="no-of-devices"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              No. of Devices
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              onChange={(e) => setNoOfDevices(e.target.valueAsNumber)}
                              value={noOfDevices}
                              type="number"
                              name="no-of-devices"
                              id="no-of-devices"
                              className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                            />
                          </div>
                        </div>

                        {/* geo */}
                        <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="no-of-ge-fence"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              No. of Geo fences
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              onChange={(e) => setGeo(e.target.valueAsNumber)}
                              value={geo}
                              type="number"
                              name="no-of-ge-fence"
                              id="no-of-ge-fence"
                              className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                            />
                          </div>
                        </div>

                        {/* usecase */}
                        <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="usecase"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              No. of Usecases
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              onChange={(e) => setUsecase(e.target.valueAsNumber)}
                              value={usecase}
                              type="number"
                              name="usecase"
                              id="usecase"
                              className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        {/* users */}
                        <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="no-of-users"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              No. of Users
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              onChange={(e) => setNoOfUsers(e.target.valueAsNumber)}
                              value={noOfUsers}
                              type="number"
                              name="no-of-users"
                              id="no-of-users"
                              className="block w-full shadow-sm sm:text-sm focus:ring-cyan-600 focus:border-cyan-600 border-gray-300 rounded-md"
                            />
                          </div>
                        </div>

                        {/* status */}

                        <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="status"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Status
                            </label>
                          </div>

                          <div className="sm:col-span-2">
                            <Switch.Group>
                              <div className="flex items-center">
                                <Switch
                                  checked={packageStatus}
                                  onChange={setPackageStatus}
                                  className={`${
                                    packageStatus
                                      ? "bg-cyan-500"
                                      : "bg-gray-200"
                                  } relative inline-flex items-center h-10 w-full rounded-md  transform transition ease-in-out duration-1900`}
                                >
                                  <span className="sr-only">Enable</span>
                                  <div
                                    className={`transform transition ease-in-out duration-1900 p-1 inline-block w-1/2 h-10 bg-white rounded-md 
                                ${
                                  packageStatus
                                    ? "translate-x-full border-4 border-cyan-500 text-cyan-500 font-medium"
                                    : "translate-x-0  border-4 border-gray-200 text-gray-300 font-medium"
                                }
                              `}
                                  >
                                    <p>
                                      {packageStatus ? "Enabled" : "Disabled"}
                                    </p>
                                  </div>
                                </Switch>
                              </div>
                            </Switch.Group>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Action buttons */}
                    <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                      {createStatus === "" ? (
                        <div className="space-x-3 flex justify-end">
                          <button
                            // type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-600"
                            // onClick={onClickCloseEditPackage}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            //   onClick={(e) => onClickCreateCompany(e)}
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-600"
                          >
                            Update
                          </button>
                        </div>
                      ) : (
                        <>
                          {" "}
                          <p className="text-md text-center text-gray-500">
                            {createStatus}
                          </p>
                        </>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
