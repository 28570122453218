import React from "react";
import { ChatBubbleLeftEllipsisIcon, CheckCircleIcon } from "@heroicons/react/20/solid";

function CreateCommentCard({onChange, onClick, src}) {
  return (
    <>
      <div className="mt-6">
        <div className="flex space-x-3">
          <div className="flex-shrink-0">
            <div className="relative">
              <img
                className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-gray-100"
                src={src}
                // src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                alt=""
              />

              <span className="absolute -bottom-0.5 -right-1 bg-gray-100 rounded-tl px-0.5 py-px">
                <ChatBubbleLeftEllipsisIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </div>
          </div>
          <div className="min-w-0 flex-1">
            <form action="#">
              <div>
                <label htmlFor="comment" className="sr-only">
                  Comment
                </label>
                <textarea
                onChange={onChange}
                  id="comment"
                  name="comment"
                  rows={3}
                  className="shadow-sm block w-full focus:ring-gray-900 focus:border-gray-900 sm:text-sm border border-gray-300 rounded-md"
                  placeholder="Leave a comment"
                  defaultValue={""}
                />
              </div>
              <div className="mt-6 flex items-center justify-end space-x-4">
                {/* <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                >
                  <CheckCircleIcon
                    className="-ml-1 mr-2 h-5 w-5 text-green-500"
                    aria-hidden="true"
                  />
                  <span>Close issue</span>
                </button> */}
                <button
                onClick={onClick}
                  type="submit"
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-cyan-500 hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                >
                  Add remarks
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateCommentCard;
